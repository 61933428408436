<template>
    <div>
        <b-container class="mt-8 pb-5">
            <b-row class="justify-content-center">
                <b-col lg="10" md="6">
                    <div class="w-100 text-center">
                        <h2 class="mb-1">{{trans('cds-form-sent',244)}}</h2>
                        <p class="mb-2">{{trans('we-will-contact-you',253)}}</p>
                    </div>
                </b-col>
            </b-row>
        </b-container>   
    </div>
</template>

<script>

export default {
    components: {
    },

    props: ["itemData"],

    created(){
    },

    computed: {
        cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }, 
        },
    },
    
    watch:{},

    data(){
        return{
        }
    },
    
    methods:{}
};
</script>

<style></style>
<style lang="scss" scope>
</style>
