<template>
    <div>
        
        <h1 class="mt-2 mb-4">{{$translate(`${this.itemData.type}.step1_header`)}}</h1>

        <b-card>
            <vue-pdf-app style="height: calc(var(--vh, 1vh)*100 - 20rem); width: 100%;" :pdf="pdf" :config="config"></vue-pdf-app>
        </b-card>
            
    </div>
</template>

<script>
import VuePdfApp from 'vue-pdf-app';
import "vue-pdf-app/dist/icons/main.css";

export default {
    components: {
        VuePdfApp
    },

    props: ["itemData"],

    created(){
        if(this.itemData.base_64 != null){
            
            var characters = atob(this.itemData.base_64);
            var array = new Uint8Array(characters.length);

            for (var i = 0; i < characters.length; i++) {
                array[i] = characters.charCodeAt(i);
            }

            this.pdf = array.buffer;
        }
        
    },

    computed: {
        cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }, 
        },
    },
    
    watch:{
    },

    data(){
        return{
            pdf: null,
            config: {
                toolbar: false
            },
        }
    },
    
    methods:{}
};
</script>

<style></style>
<style lang="scss" scope>
.pdfContainer {
    text-align: center;
}

.viewPDF {
    border: 1px solid #71e6ee;
    margin-left: auto;
    margin-right: auto;
    min-width: 400px;
    max-width: 500px;
}

.paginationContainer {
    margin-top: 30px;
}

.printButton {
    float: right;
}

.content-area-wrapper{
    height: calc(var(--vh, 1vh)*100 - 14rem);
    display: -webkit-box;
    display: flex;
    position: relative;
    overflow: hidden;
}

.pdf-app .pdfViewer .page{
    border: none !important;
    margin: 6px auto 12px auto !important;
}
</style>
